import { RouteMiddleware } from "@src/types/router";
import { authMiddleware } from "./authMiddleware";
import { csrfMiddleware } from "./csrfMiddleware";
import { reloadMeMiddleware } from "./reloadMeMiddleware";
import { themeColorMiddleware } from "./themeColorMiddleware";
import { userRequirementsMiddleware } from "./userRequirementsMiddleware";
import { deleteAccountMiddleware } from "./deleteAccountMiddleware";

const middlewares: Record<string, RouteMiddleware> = {
  auth: authMiddleware,
  reloadMe: reloadMeMiddleware,
  csrf: csrfMiddleware,
  themeColor: themeColorMiddleware,
  userRequirements: userRequirementsMiddleware,
  deleteAccount: deleteAccountMiddleware,
};

export default middlewares;
