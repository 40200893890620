import tippy, { Instance, Props } from "tippy.js";
import { Directive, Plugin } from "vue";

const tippyPlugin: Plugin = {
  install(app) {

    const directive: Directive<Element, Props> = {
      mounted(el, binding) {
        if (binding.value) {
          tippy(el, binding.value);
        }
      },
      updated(el, binding) {
        if (binding.value) {
          const _el = (el as Element & { _tippy?: Instance });
          if(_el._tippy) {
            _el._tippy.setProps(binding.value);
          } else {
            tippy(el, binding.value);
          }
        }
      },
      beforeUnmount(el) {
        const _el = (el as Element & { _tippy?: Instance });
        _el?._tippy?.destroy();
      },
    };

    app.directive("tippy", directive);
  },
};

export default tippyPlugin;
