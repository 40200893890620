<template>
  <component
    :is="component"
    ref="target"
    :tabindex="disabled ? -1 : 0"
    :disabled="isDisabled"
    :class="classes"
    :href="href"
    v-bind="attrs"
  >
    <slot name="prefix"></slot>
    <slot></slot>
    <slot name="suffix"></slot>
  </component>
</template>

<script lang="ts" setup>
import { useTippy } from "@lib/composables/useTippy";
import {
  Shape,
  Variant,
  sizeClasses,
  sizeClassesMd,
  sizeClassesLg,
  sizeClassesXl,
  variantClasses,
  roundedClasses,
  shapeClasses,
} from "@lib/types/components/YButton";
import { Rounded } from "@lib/types/rounded";
import { Size } from "@lib/types/sizes";
import { Props as TippyProps } from "tippy.js";

export interface Props {
  outline?: boolean;
  borderless?: boolean;
  disabled?: boolean;
  href?: string;
  is?: any;
  loading?: boolean;
  wide?: boolean;
  glass?: boolean;
  size?: Size;
  sizeMd?: Size;
  sizeLg?: Size;
  sizeXl?: Size;
  shape?: Shape;
  variant?: Variant;
  rounded?: Rounded;
  tippy?: Partial<TippyProps>;
}

const props = withDefaults(defineProps<Props>(), {
  size: "md",
  variant: "accent",
  rounded: "normal",
});

const target = ref<HTMLElement>();

/*** Button group props */
const groupSize = inject<Size | undefined>("groupSize", undefined);
const groupSizeMd = inject<Size | undefined>("groupSizeMd", props.sizeMd);
const groupSizeLg = inject<Size | undefined>("groupSizeLg", props.sizeLg);
const groupSizeXl = inject<Size | undefined>("groupSizeXl", props.sizeXl);
const groupRounded = inject<Rounded | undefined>("groupRounded", undefined);
const groupDisabled = inject<boolean | undefined>("groupDisabled", undefined);
const groupOutline = inject<boolean | undefined>("groupOutline", undefined);
/************************/

const sizeMd = computed(() => props.sizeMd ?? groupSizeMd);
const sizeLg = computed(() => props.sizeLg ?? groupSizeLg);
const sizeXl = computed(() => props.sizeXl ?? groupSizeXl);

const responsiveSizeClasses = computed(() => [
  sizeClasses[groupSize ?? props.size],
  ...(sizeMd.value ? [sizeClassesMd[sizeMd.value]] : []),
  ...(sizeLg.value ? [sizeClassesLg[sizeLg.value]] : []),
  ...(sizeXl.value ? [sizeClassesXl[sizeXl.value]] : []),
]);

const attrs = useAttrs();

const component = computed(() => {
  if (props.is) {
    return props.is;
  }
  if (props.href || attrs.onClick) {
    return "a";
  }
  return "button";
});

const classes = computed(() => [
  "btn",
  {
    "btn-outline": props.outline || groupOutline,
    "border-0": props.borderless,
    loading: props.loading,
    "btn-wide": props.wide,
    glass: props.glass,
  },
  responsiveSizeClasses.value,
  variantClasses[props.variant],
  roundedClasses[groupRounded ?? props.rounded],
  props.shape ? shapeClasses[props.shape] : [],
]);

const isDisabled = computed(() => props.disabled || groupDisabled);

const tippyProps = toRef(props, "tippy");

watchEffect(() => {
  if (tippyProps.value) {
    useTippy(target, tippyProps);
  }
});

defineExpose({
  $el: target,
  $instance: getCurrentInstance(),
});
</script>
