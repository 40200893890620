<template>
    <YToaster
        v-for="position in usedPositions"
        :key="position"
        :toast-list="toastsByPosition[position] ?? []"
        :position="position"
        @fade="fade"
    />
</template>

<script setup lang="ts">
import { ByPosition, Config, defaultValue, List, Position, Toast, ToastFunction } from '@lib/types/plugins/ToastPlugin';
import YToaster from './YToaster.vue';

const toastList = reactive<List>([]);
const usedPositions = reactive<Position[]>([]);

const toast: ToastFunction = (config: Config) => {
    let toast: Toast | undefined = undefined;
    if (typeof config === "object") {
        toast = {
            ...defaultValue(),
            ...config
        } as Toast;
    } else {
        toast = {
            ...defaultValue(),
            title: config
        } as Toast;
    }
    if (!usedPositions.includes(toast.position)) {
        usedPositions.push(toast.position);
    }

    toastList.push(toast);
}

const toastsByPosition = computed<ByPosition>(() => {
    const toastsByPosition: ByPosition = {};

    toastList.forEach((toast) => {
        if (!toastsByPosition[toast.position]) {
            toastsByPosition[toast.position] = [];
        }
        toastsByPosition[toast.position]?.push(toast);
    })

    return toastsByPosition;
})

const fade = (key: number) => {
    toastList.splice(key, 1);
}

const instance = getCurrentInstance();
if(instance)
    instance.appContext.config.globalProperties.$toast = toast;
</script>