<template>
  <div
    v-if="
      genericStore.languages.data && genericStore['language-proficiencies'].data
    "
    class="space-y-5 md:space-y-3"
  >
    <div
      v-for="(_token, index) of items"
      :key="_token"
      type="form"
      class="grid-cols-[1fr,auto] w-full gap-x-3"
      :class="items.length > 1 ? 'grid' : 'flex'"
      :value="context._value?.[index] ?? {}"
    >
      <div class="flex flex-col items-center flex-1 gap-3 md:flex-row">
        <PlanySelect
          :model-value="context._value?.[index]?.language"
          :options="(getLanguages(context._value?.[index] ?? {}) as any)"
          class="flex-1 w-full h-full text-lg"
          :placeholder="capitalize($t('properties.lang'))"
          filter
          @update:model-value="(value: any) => setValue(index, 'language', value)"
        />
        <PlanySelect
          :model-value="context._value?.[index]?.proficiency"
          :options="(getLanguageProficiencies(context._value?.[index] ?? {}) as any)"
          class="flex-1 w-full h-full text-lg"
          :placeholder="capitalize($t('properties.level'))"
          @update:model-value="(value: any) => setValue(index, 'proficiency', value)"
        />
      </div>
      <YButton
        v-if="items.length > 1"
        variant="error"
        class="flex h-full"
        :disabled="disableAdd && !!context._value?.[index]?.language && !!context._value?.[index]?.proficiency"
        @click.prevent="removeLanguage(index)"
      >
        <fa-solid-trash />
      </YButton>
    </div>
    <YButton
      variant="neutral"
      borderless
      outline
      class="w-full"
      :disabled="disableAdd"
      @click.prevent="items.push(token())"
    >
      {{ $t("wizard.steps.miscs.add_language") }}
    </YButton>
  </div>
</template>

<script setup lang="ts">
import { SpokenLanguage } from "@src/types/services/wizard.service";
import { token } from "@formkit/utils";
import { useGenericStore } from "@src/store/generic.store";
import { capitalize } from "vue";

interface Context {
  node: {
    input: (val: Partial<SpokenLanguage>[]) => any;
    name: string;
  };
  _value: Partial<SpokenLanguage>[] | undefined;
  value: Partial<SpokenLanguage>[] | undefined;
}

const props = defineProps<{ context: Context }>();
const genericStore = useGenericStore();
genericStore.fetch("languages", "language-proficiencies");
const items = ref<string[]>([]);

watch(
  () => props.context._value,
  (languages, old) => {
    if (languages && !old) {
      items.value = Array.from(languages).map(() => token());
    }

    if (languages && old) {
      const diff = languages.length - old.length;
      if (diff) {
        items.value = Array.from(languages).map(() => token());
      }
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

function setValue(index: number, key: keyof SpokenLanguage, value: any) {
  const _value = props.context._value;

  if (_value) {
    if (!_value[index]) {
      _value[index] = {
        language: undefined,
        proficiency: undefined,
        [key]: value,
      };
    } else {
      _value[index][key] = value;
    }
    props.context.node.input(_value);
  }
}

const getLanguages = computed(
  () => (spoken: Partial<SpokenLanguage> | undefined) =>
    unref(genericStore.languages.data ?? [])
      .filter((value) => {
        if (
          value.id !== spoken?.language &&
          props.context.value?.find((_spoken) => _spoken.language === value.id)
        ) {
          return false;
        }
        return true;
      })
      .map((value) => ({
        value: value.id,
        label: capitalize(value.label),
      }))
);

const getLanguageProficiencies = computed(
  () => (spoken: Partial<SpokenLanguage> | undefined) =>
    unref(genericStore["language-proficiencies"].data ?? [])
      .filter((value) => {
        if (
          value.id === 5 &&
          props.context.value?.find((_spoken) => _spoken.proficiency === 5) &&
          spoken?.proficiency !== 5
        ) {
          return false;
        }
        return true;
      })
      .map((value) => ({
        value: value.id,
        label: value.label,
      }))
);

function removeLanguage(index: number) {
  const _value = [...(props.context._value ?? [])];
  if (_value[index] && items.value[index]) {
    _value.splice(index, 1);
    props.context.node.input(_value);
  }
  items.value.splice(index, 1);
}

const disableAdd = computed(() => {
  const last = props.context._value?.[items.value.length - 1];
  return !last || !last.language || !last.proficiency;
});
</script>
