<template>
  <Transition name="toast" mode="out-in" appear>
    <TransitionGroup
      name="toast"
      mode="out-in"
      appear
      class="y-toaster"
      :class="toastPositionClasses[position]"
      tag="div"
    >
      <YToast
        v-for="(toast, key) in toastList"
        :key="toast.uuid"
        :toast="toast"
        @fade="fade(key)"
      />
    </TransitionGroup>
  </Transition>
</template>

<script setup lang="ts">
import {
  List,
  Position,
  toastPositionClasses,
} from "@lib/types/plugins/ToastPlugin";
import YToast from "./YToast.vue";

interface Props {
  toastList: List;
  position: Position;
}

interface Emits {
  (e: "fade", value: number): void;
}

defineProps<Props>();
const emits = defineEmits<Emits>();

const fade = (key: number) => {
  emits("fade", key);
};
</script>

<style>
.y-toaster {
  @apply fixed flex flex-col-reverse gap-4 m-4 z-[100];

  &.y-toaster--top-left {
    @apply top-0 left-0;
  }
  &.y-toaster--top-right {
    @apply top-0 right-0;
  }
  &.y-toaster--top-center {
    @apply top-0 left-[calc(50%-1rem)] transform translate-x-[-50%] w-[90%] lg:w-auto;
  }

  &.y-toaster--bottom-left {
    @apply bottom-0 left-0;
  }
  &.y-toaster--bottom-right {
    @apply bottom-0 right-0;
  }
  &.y-toaster--bottom-center {
    @apply bottom-0 left-[50%] transform translate-x-[-50%] w-[90%] lg:w-auto;
  }

  .toast-enter-active,
  .toast-leave-active {
    transition: opacity 0.5s ease;
  }

  .toast-enter-from,
  .toast-leave-to {
    opacity: 0;
  }
}
</style>
