export const base: Record<string, string> = {
  label: "$reset label",
  messages: "$reset label text-sm flex-col items-start",
  prefixIcon:
    "$reset h-full pl-3 flex items-center text-primary-color-600 dark:text-primary",
  suffixIcon:
    "$reset h-full pr-3 flex items-center text-primary-color-600 dark:text-primary",
  help: "$reset mt-2 text-sm",
};

export const invalid: Record<keyof typeof base, string> = {
  label: "formkit-invalid:text-error-content formkit-invalid:dark:text-error",
  messages:
    "formkit-invalid:text-error-content formkit-invalid:dark:text-error",
  prefixIcon:
    "formkit-invalid:text-error-content formkit-invalid:dark:text-error",
  suffixIcon:
    "formkit-invalid:text-error-content formkit-invalid:dark:text-error",
  help: "formkit-invalid:text-error-content formkit-invalid:dark:text-error",
};

export const errors: Record<keyof typeof base, string> = {
  label: "formkit-errors:text-error-content formkit-errors:dark:text-error",
  messages: "formkit-errors:text-error-content formkit-errors:dark:text-error",
  prefixIcon:
    "formkit-errors:text-error-content formkit-errors:dark:text-error",
  suffixIcon:
    "formkit-errors:text-error-content formkit-errors:dark:text-error",
  help: "formkit-errors:text-error-content formkit-errors:dark:text-error",
};
