import { Ref } from "vue";
import { useAsyncHandler } from "./useAsyncHandler";

export function useAsyncData<U>(handler: () => Promise<U>, defaultValue?: U) {
  const { loading, handle } = useAsyncHandler(handler);
  const data = ref(defaultValue) as Ref<U>;

  async function evaluate() {
    data.value = await handle();
  }

  return {
    data,
    evaluating: loading,
    evaluate,
  };
}
