import { Plugin } from "vue";
import { createYnalpExtra } from "@lib/utils/ynalpExtras";
import YToasterContainer from "@lib/components/YToast/YToasterContainer.vue";

export const toastPlugin: Plugin = {
  install(app) {
    const toastContainer = createYnalpExtra("y-toast");
    const toastApp = createApp(YToasterContainer);
    toastApp.mount(toastContainer);
    app.provide("$toast", toastApp.config.globalProperties.$toast);
    app.config.globalProperties.$toast =
      toastApp.config.globalProperties.$toast;
  },
};
