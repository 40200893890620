import { AfterResponseHook } from "ky";
import nProgress from "nprogress";
import unauthResponseHook from "./unauth";
const afterResponseHooks: AfterResponseHook[] = [
  () => {
    nProgress.done();
  },
  unauthResponseHook
];

export default afterResponseHooks;
