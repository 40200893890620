export function getUploadMaxFileSize(size: string) {
  const letter = size.slice(-1);
  const number = parseInt(size.slice(0, -1));

  if (isNaN(number)) throw new Error("File size validator: Wrong size format");

  let pow = 0;

  switch (letter) {
    case "K":
      pow = 1;
      break;
    case "M":
      pow = 2;
      break;
    case "G":
      pow = 3;
      break;
  }

  return number * Math.pow(1024, pow);
}
