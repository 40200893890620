<template>
    <YModal
        v-model:open="open"
        modal-class="w-full max-w-screen-sm overflow-hidden"
    >
        <div class="space-y-4 w-full">
            <h3>{{ $t("fusion_modal.title") }}</h3>
            <p>{{ $t('fusion_modal.text') }}</p>
            <YAlert variant="info">
                <span v-html="$t('fusion_modal.alert')" />
            </YAlert>
            <FormKit
                type="form"
                @submit="onSubmit"
            >
                <template #default="{ value }">
                    <FormKit
                        type="email"
                        name="email"
                        :label="$t('fusion_modal.email_label')"
                        :validation-label="$t('properties.email')"
                        :validation="`required|email|not:${value?.['auth-email']}`"
                        :validation-messages="{ not: $t('fusion_modal.same_email_error') }"
                        inner-class="bg-base-200 shadow shadow-base-300/50"
                        label-class="!pl-0"
                        outer-class="mb-2"
                    />
                    <FormKit
                        id="auth-email"
                        :type="authStore.user?.email ? 'hidden' : 'email'"
                        name="auth-email"
                        validation="required|email"
                        :validation-label="$t('properties.email')"
                        :label="$t('fusion_modal.auth_email_label')"
                        :value="authStore.user?.email"
                        inner-class="bg-base-200 shadow shadow-base-300/50"
                        label-class="!pl-0"
                        outer-class="mb-4"
                    />
                    <YAlert variant="error" class="mb-4">{{ $t('fusion_modal.alert_email') }}</YAlert>
                </template>
                <template #messages></template>
                <template #actions="{ state }">
                    <YButton
                        type="submit"
                        variant="secondary"
                        class="w-full"
                        :disabled="!state.valid"
                    >
                        {{ $t('send') }}
                    </YButton>
                </template>
            </FormKit>
        </div>
    </YModal>
</template>

<script setup lang="ts">
import { useAuthStore } from '@src/store/auth.store';
import queryString from 'query-string';

const { t } = useI18n();

const authStore = useAuthStore();

const open = defineModel<boolean>("open", {
    default: false,
    local: true
});

function onSubmit(data: { email: string, 'auth-email': string }) {
    open.value = false;
    const body = t("fusion_modal.email", {
        email: data.email, newLine: "\r\n",
        currentEmail: t("fusion_modal.current_email", {
            email: data['auth-email'],
            newLine: "\r\n"
        })
    })
    // eslint-disable-next-line import/namespace
    window.location.href = queryString.stringifyUrl({
        url: "mailto:support-future@plany.fr",
        query: {
            subject: t("fusion_modal.email_subject"),
            body
        }
    })
}
</script>