<template>
  <PlanySelect
    :id="context.id"
    v-model="vmodel"
    :options="context.options"
    :class="context.classes.input"
    v-bind="context.attrs"
    @blur="context.handlers.blur"
  />
</template>

<script setup lang="ts">
import { PlanySelectOptions } from "@src/components/generic/PlanySelect/PlanySelect.vue";

interface Context {
  id: string;
  node: {
    input: (val: any) => any;
    name: string;
  };
  handlers: {
    blur: () => any;
  };
  disabled: boolean;
  _value: any;
  value: any;
  classes: any;
  attrs: Record<string, any>;
  options: PlanySelectOptions;
}

interface Props {
  context: Context;
}

const props = defineProps<Props>();

const vmodel = computed({
  get() {
    return props.context.value;
  },
  set(value) {
    props.context.node.input(value);
  },
});
</script>
