<template>
  <div ref="container" class="flex flex-wrap w-full gap-2">
    <select
      v-model="day"
      class="flex-1 !px-2 appearance-none lg:px-4 input group"
      :class="[
        context.classes.inner,
        { 'text-placeholder focus:text-base-content': day === null },
      ]"
      v-bind="context.dayAttrs"
      @blur="onBlur"
    >
      <option :value="null">
        {{ context.dayPlaceholder }}
      </option>
      <option v-for="_day in 31" :key="_day" :value="_day">{{ _day }}</option>
    </select>
    <select
      v-model="month"
      class="!px-2 appearance-none flex-[2] lg:px-4 input group"
      :class="[
        context.classes.inner,
        { 'text-placeholder focus:text-base-content': month === null },
      ]"
      v-bind="context.monthAttrs"
      @blur="onBlur"
    >
      <option :value="null">
        {{ context.monthPlaceholder }}
      </option>
      <option v-for="_month in 12" :key="_month" :value="_month">
        {{ capitalize(dayjs(`1990-${_month}-01`, "YYYY-M-DD").format("MMMM")) }}
      </option>
    </select>
    <select
      v-model="year"
      class="flex-1 !px-2 appearance-none lg:px-4 input group"
      :class="[
        context.classes.inner,
        { 'text-placeholder focus:text-base-content': year === null },
      ]"
      v-bind="context.yearAttrs"
      @blur="onBlur"
    >
      <option class="font-light" :value="null">
        {{ context.yearPlaceholder }}
      </option>
      <option v-for="_year in 80" :key="_year" :value="maxYear - (_year - 1)">
        {{ maxYear - (_year - 1) }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import dayjs from "@src/utils/dayjs";
import { capitalize } from "vue";
interface Context {
  id: string;
  node: {
    input: (val: string | undefined) => any;
    name: string;
  };
  handlers: {
    blur: () => any;
  };
  disabled: boolean;
  value: any;
  classes: any;
  attrs: Record<string, any>;
  dateFormat?: string;
  dayPlaceholder?: string;
  monthPlaceholder?: string;
  yearPlaceholder?: string;
  dayAttrs?: Record<string, any>;
  monthAttrs?: Record<string, any>;
  yearAttrs?: Record<string, any>;
}
const props = defineProps<{ context: Context }>();
const maxYear = new Date().getFullYear() - 16;

interface State {
  day: number | null;
  month: number | null;
  year: number | null;
}

const container = ref<HTMLElement>();

const state = reactive<State>({ day: null, month: null, year: null });
const { day, month, year } = toRefs(state);

const { pause: pauseState, resume: resumeState } = watchPausable(
  state,
  (state) => {
    const date = dayjs(
      `${state.year}-${state.month}-${state.day}`,
      "YYYY-M-D",
      true
    );
    if (date.isValid()) {
      props.context.node.input(date.format("YYYY-MM-DD"));
    } else {
      props.context.node.input(undefined);
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

watch(
  () => props.context.value,
  (value) => {
    const date = dayjs(`${value}`, "YYYY-MM-DD");

    if (date.isValid()) {
      pauseState();
      if (date.date() !== day.value) day.value = date.date();
      if (date.month() !== month.value) month.value = date.month() + 1;
      if (date.year() !== year.value) year.value = date.year();
      resumeState();
    }
  },
  {
    immediate: true,
  }
);

function onBlur() {
  setTimeout(() => {
    if (!container.value?.contains(document.activeElement)) {
      props.context.handlers.blur();
    }
  }, 100);
}
</script>
