<template>
  <label
    class="relative flex cursor-text flex-wrap items-center gap-x-2 gap-y-1"
    v-bind="attrs"
  >
    <template v-if="values?.length">
      <span
        v-for="(val, key) in values"
        :key="val"
        class="flex items-center justify-center gap-1 rounded-full bg-primary py-0.5 pl-2 pr-1 text-sm text-primary-content"
      >
        <span class="pb-0.5">{{ val }}</span>
        <a
          href="#"
          class="flex h-5 w-5 items-center justify-center rounded-full bg-primary-content text-xs text-primary opacity-70 hover:opacity-100"
          @click.prevent="emits('remove', key)"
        >
          <fa-regular-xmark />
        </a> </span
    ></template>
    <input
      v-else
      :placeholder="placeholder"
      class="pointer-events-none bg-transparent"
    />
  </label>
</template>

<script setup lang="ts">
interface Props {
  placeholder?: string;
  values?: string[];
}

interface Emits {
  (e: "remove", value: number): void;
}

defineProps<Props>();
const emits = defineEmits<Emits>();
const attrs = useAttrs();
</script>
