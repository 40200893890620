import {
  base as inputBase,
  errors as inputErrors,
  invalid as inputInvalid,
} from "./base";

const base: Record<string, string> = {
  ...inputBase,
  inner:
    "$reset textarea textarea-primary flex items-center px-0 border-transparent focus-within:border-primary formkit-disabled:opacity-50",
  input: "$reset block w-full h-full bg-transparent border-0 outline-none px-3",
};

const invalid: Record<keyof typeof base, string> = {
  ...inputInvalid,
  inner:
    "formkit-invalid:textarea-error formkit-invalid:border-error-content formkit-invalid:dark:border-error",
};

const errors: Record<keyof typeof base, string> = {
  ...inputErrors,
  inner:
    "formkit-errors:textarea-error formkit-errors:border-error-content formkit-errors:dark:border-error",
};

Object.entries(base).forEach((value: [keyof typeof base, string]) => {
  const key = value[0];
  const val = value[1];

  base[key] += `${val} ${invalid[key]} ${errors[key]}`;
});

export default base;
