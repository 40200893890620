import { Component } from "vue";
import { Size } from "../sizes";

/* eslint-disable import/no-unresolved */
import FaRegularCircleInfo from '~icons/fa-regular/circle-info';
import FaRegularTriangleExclamation from '~icons/fa-regular/triangle-exclamation';
import FaRegularBan from '~icons/fa-regular/ban';
/* eslint-enable import/no-unresolved */

export const variants = ["neutral", "info", "success", "warning", "error"] as const;
export type Variant = typeof variants[number];
export const variantClasses: Record<Variant, string> = {
    neutral: "text-base-content border-base-100",
    info: "alert-info border-info",
    success: "alert-success border-success",
    warning: "alert-warning border-warning dark:text-yellow-900",
    error: "alert-error border-error"
};

export const variantIcons: Record<Variant, Component> = {
    neutral: FaRegularCircleInfo,
    info: FaRegularCircleInfo,
    success: FaRegularCircleInfo,
    warning: FaRegularTriangleExclamation,
    error: FaRegularBan
}

export const sizeClasses: Record<Size, string> = {
    lg: 'p-6 text-lg',
    md: 'p-4',
    sm: 'py-2 px-3 text-sm',
    xs: 'py-1 px-2 text-xs'
}
