import { Component } from 'vue';
/* eslint-disable import/no-unresolved */
import FaDuotoneCircleExclamation from '~icons/fa-duotone/circle-exclamation';
import FaDuotoneCircleQuestion from '~icons/fa-duotone/circle-question';
import FaDuotoneCircleCheck from '~icons/fa-duotone/circle-check';
import FaDuotoneTriangleExclamation from '~icons/fa-duotone/triangle-exclamation';
import FaDuotoneCircleXmark from '~icons/fa-duotone/circle-xmark';
/* eslint-enable import/no-unresolved */
import { Props } from "../components/YButton";

export const types = ['default', 'info', 'success', 'warning', 'error'] as const;
export type Type = typeof types[number];

export const typeIcons: Record<Type, { class: string, icon: Component }> = {
    default: {
        class: 'text-black/50 dark:text-base-content',
        icon: FaDuotoneCircleExclamation
    },
    info: {
        class: 'text-primary-color-600 dark:text-info',
        icon: FaDuotoneCircleQuestion
    },
    success: {
        class: 'text-success-content dark:text-success',
        icon: FaDuotoneCircleCheck
    },
    warning: {
        class: 'text-yellow-500 dark:text-yellow-200',
        icon: FaDuotoneTriangleExclamation
    },
    error: {
        class: 'text-error-content dark:text-error',
        icon: FaDuotoneCircleXmark
    }
}

export interface ModalExpose {
    close: () => void,
    setButtonLoading: (name: string, loading?: boolean) => void
}

export interface Button {
    name: string,
    label: string,
    buttonProps: Props,
    onClick?: (ctx: ModalExpose) => unknown | void
}

export interface Modal {
    type?: Type,
    title?: string,
    text?: string,
    backdrop?: boolean,
    backdropClass?: string,
    backdropClose?: boolean,
    modalClass?: string,
    closeButton?: boolean,
    buttons?: Button[],
    onClose?: () => void
}

export type ModalFunction = (config: Modal) => {
    close: () => void
};