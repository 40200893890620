import { AfterResponseHook } from "ky";

const whitelist = ["/accounts/me", "/login"];

const unauthResponseHook: AfterResponseHook = (request, options, response) => {
  if (
    whitelist.find((value) =>
      request.url.startsWith(`${import.meta.env.VITE_SERVER_BASEURL}${value}`)
    )
  )
    return;

  if (response.status === 401) {
    window.location.href = "/login";
    return;
  }
};

export default unauthResponseHook;
