import { RequiredAction } from "@src/types/services/responses";
import { EventBusKey } from "@vueuse/core";

const busKey: EventBusKey<RequiredAction> = Symbol("ACTIONS-BUS-KEY");
const busCallbackKey: EventBusKey<{ code: string; data?: any }> = Symbol(
  "ACTIONS-BUS-CALLBACK-KEY"
);

export const actionsBus = useEventBus(busKey);
export const actionsCallbackBus = useEventBus(busCallbackKey);
