import { BeforeRequestHook } from "ky";

const xsrfHook: BeforeRequestHook = (request) => {
  let token = document.cookie
    .split("; ")
    .find((row) => row.startsWith("XSRF-TOKEN="))
    ?.split("=")[1];
  if (token) {
    token = token.replace("%3D", "=");
    request.headers.set("X-XSRF-TOKEN", token);
  }

  return request;
};

export default xsrfHook;
