import tippy, { Instance } from "tippy.js";
import { Directive, Plugin } from "vue";

const truncatPlugin: Plugin = {
  install(app) {
    const directive: Directive<HTMLElement> = {
      mounted(el) {
        el.classList.add("truncate");
        nextTick(() => {
          if (el.offsetWidth < el.scrollWidth) {
            tippy(el, {
              allowHTML: true,
              content: el.innerHTML,
            });
          }
        });
      },
      updated(el) {
        const _el = el as HTMLElement & { _tippy?: Instance };
        if(el.offsetWidth < el.scrollWidth) {
          if (_el._tippy) {
            _el._tippy.setProps({
              content: el.innerHTML,
            });
          } else {
            tippy(el, {
              allowHTML: true,
              content: el.innerHTML,
            });
          }
        } else {
          _el._tippy?.destroy();
        }
      },
      beforeUnmount(el) {
        const _el = el as HTMLElement & { _tippy?: Instance };
        _el?._tippy?.destroy();
      },
    };

    app.directive("truncat", directive);
  },
};

export default truncatPlugin;
