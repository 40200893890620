<template>
    <YModalAlert
        v-for="modal in modals"
        :key="modal.key"
        :ref="(el: unknown) => setModalRef(el as YModalAlertExposed, modal.key)"
        v-bind="modal.modal"
    />
</template>

<script setup lang="ts">
import { Modal, ModalFunction } from '@lib/types/plugins/ModalAlertPlugin';
import { v4 as uuid } from "uuid";
import { ModalAlertBus } from '@lib/plugins/ModalAlertPlugin';

type YModalAlertExposed = {
    close: () => void
};

const modals = ref<{ key: string, modal: Modal }[]>([]);
const modalsRefs = ref<Record<string, YModalAlertExposed>>({});

const setModalRef = (el: YModalAlertExposed, key: string) => modalsRefs.value[key] = el;

const onModal: ModalFunction = (config: Modal) => {
    const key = uuid();

    const remove = () => {
        modals.value.splice(
            modals.value.findIndex((modal) => modal.key === key),
            1
        );
        delete modalsRefs.value[key];
    }

    modals.value.push(
        {
            key,
            modal: {
                onClose() {
                    setTimeout(remove, 200);
                },
                ...config
            }
        }
    );

    return {
        close() {
            modalsRefs.value[key]?.close();
        }
    }
};

ModalAlertBus.bus.on(onModal);
</script>