import { createI18n } from "vue-i18n";
import { useAuthStore } from "@src/store/auth.store";
import { setLocale } from "@src/utils/dayjs";
import { AllowedLocale, allowedLocales } from "@src/types/i18n";
import {
  setOptions,
  localeFr,
  localeEn,
  localeDe,
  localeEs,
  localeIt,
  MbscLocale,
} from "@mobiscroll/javascript";

const mobiscrollLocales: Record<AllowedLocale, MbscLocale> = {
  fr: localeFr,
  de: localeDe,
  it: localeIt,
  en: localeEn,
  es: localeEs,
};

function getLanguage() {
  const navigatorLanguage = navigator.language.toLowerCase().split("-")[0];
  const lang: AllowedLocale = [...allowedLocales].includes(
    navigatorLanguage as AllowedLocale
  )
    ? (navigatorLanguage as AllowedLocale)
    : "en";

  const locale = allowedLocales.includes(lang) ? lang : "en";
  setLocale(locale);
  setOptions({
    locale: mobiscrollLocales[locale],
  });

  return locale;
}

export const i18n = createI18n({
  locale: getLanguage(),
  fallbackLocale: "en",
  legacy: false,
  modifiers: {
    genderify: (str: any) => {
      const authStore = useAuthStore();
      const splitted = str.toString().split(";");
      if (splitted.length === 2) {
        return splitted[
          typeof authStore.user?.gender === "number" ? authStore.user.gender : 0
        ];
      }
      if (splitted.length === 3) {
        return splitted[
          typeof authStore.user?.gender === "number" ? authStore.user.gender : 3
        ];
      }
      return str;
    },
  },
});

export async function loadLocaleMessages(locale: AllowedLocale) {
  if (Object.keys(i18n.global.getLocaleMessage(locale) ?? {}).length === 0) {
    const messages = await import(`@src/lang/${locale}.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
  }
}

export async function setI18nLocale(locale: any) {
  const _locale: AllowedLocale = allowedLocales.includes(locale)
    ? locale
    : "en";

  document.documentElement.lang = _locale;

  await loadLocaleMessages(_locale);
  await setLocale(_locale);
  setOptions({
    locale: mobiscrollLocales[_locale],
  });
  i18n.global.locale.value = _locale;
}

export default i18n;
