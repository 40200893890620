import {
  LookupAgencyResponse,
  LookupResponse,
  Mission,
} from "@src/types/services/agency.service";
import { HTTPError } from "ky";
import { fetch } from "./fetch";

export default class AgencyService {
  public static async lookup(email: string) {
    const response = await fetch.get("agencies/lookup?", {
      searchParams: {
        "filter[email]": email,
      },
    });

    const data: LookupResponse = await response.clone().json();

    return data.data;
  }

  public static async lookupAgency(agency: string, includes = false) {
    try {
      const response = await fetch.get(
        `agencies/${agency}/lookup${
          includes ? "?includes[]=sub-agencies&&includes[]=business-units" : ""
        }`
      );
      const data: LookupAgencyResponse = await response.clone().json();
      return data.data;
    } catch (e) {
      if (e instanceof HTTPError && e.response.status === 404) return;
      throw e;
    }
  }

  public static async linkAgency(
    agency: string,
    json?: Record<string, number | null | undefined>
  ) {
    return await fetch.post(`accounts/${agency}`, {
      json,
    });
  }

  public static async getMissions(
    agency: string
  ): Promise<{ data: Mission[] }> {
    const response = await fetch.get(`accounts/${agency}/missions`);
    return await response.clone().json();
  }


  public static async unregister(agency: string) {
    await fetch.delete(`accounts/${agency}`);

    return true;
  }

  public static async mute(agency: string) {
    await fetch.post(`accounts/${agency}/mute`);
    return true;
  }

  public static async unmute(agency: string) {
    await fetch.delete(`accounts/${agency}/mute`);
    return true;
  }
}
