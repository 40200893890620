import {
  base as inputBase,
  errors as inputErrors,
  invalid as inputInvalid,
} from "./base";

const base: Record<string, string> = {
  ...inputBase,
  inner:
    "$reset",
  input:
    "$reset min-h-12 leading-[2.9rem] h-auto select font-normal select-primary block w-full border-transparent bg-base-100 px-3 formkit-disabled:opacity-50",
};

const invalid: Record<keyof typeof base, string> = {
  ...inputInvalid,
  input:
    "formkit-invalid:select-error formkit-invalid:border-error-content formkit-invalid:dark:border-error",
};

const errors: Record<keyof typeof base, string> = {
  ...inputErrors,
  input:
    "formkit-errors:select-error formkit-errors:border-error-content formkit-errors:dark:border-error",
};

Object.entries(base).forEach((value: [keyof typeof base, string]) => {
  const key = value[0];
  const val = value[1];

  base[key] += `${val} ${invalid[key]} ${errors[key]}`;
});

export default base;
