import ky from "ky";
import nProgress from "nprogress";
import afterResponse from "./afterResponseHooks";
import beforeError from "./beforeErrorHooks";
import beforeRequest from "./beforeRequestHooks";

export const fetch = ky.create({
  prefixUrl: import.meta.env.VITE_SERVER_BASEURL as string,
  credentials: "include",
  headers: {
    Accept: "application/json",
  },
  onDownloadProgress(progress) {
    if (nProgress.isStarted()) nProgress.set(progress.percent);
  },
  hooks: {
    beforeRequest,
    afterResponse,
    beforeError,
  },
  retry: 0,
  timeout: 30000
});

export const fetchLocal = fetch.extend({
  prefixUrl: "/",
});
