import { MaybeRef } from "@vueuse/core";
import { Promisable } from "type-fest";

export function usePullToRefresh(
  target: MaybeRef<EventTarget>,
  handler: () => Promisable<any>,
  threshold = 100
) {
  const { isScrolling } = useScroll(document, {
    eventListenerOptions: {
      capture: true,
    },
  });

  const { isSwiping, direction, lengthY } = useSwipe(target, {
    async onSwipeEnd(e, dir) {
      if (!isScrolling.value && dir === "down" && -lengthY.value >= threshold) {
        isRefreshing.value = true;
        await handler();
        isRefreshing.value = false;
      }
    },
    threshold: 10,
  });
  const isRefreshing = ref(false);
  const isPulling = computed(
    () => !isScrolling.value && isSwiping.value && direction.value === "down"
  );
  const length = computed(() => (-lengthY.value) <= threshold ? -lengthY.value : threshold );

  return {
    isRefreshing,
    isPulling,
    length,
  };
}
