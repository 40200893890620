<template>
  <div
    :id="context.id"
    :name="context.node.name"
    class="flex w-full flex-wrap items-center gap-4"
  >
    <YButton
      v-for="option in context.options"
      :key="`${option.value}`"
      :outline="selectedOption?.value !== option.value"
      :variant="appStore.isDark ? 'primary' : 'neutral'"
      class="flex-1"
      :class="context.classes.input"
      v-bind="context.attrs"
      @click.prevent="context.node.input(option.value)"
    >
      {{ option.label }}
    </YButton>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@src/store/app.store";

interface Context {
  id: string;
  node: {
    input: (val: string) => any;
    name: string;
  };
  handlers: {
    blur: () => any;
  };
  disabled: boolean;
  _value: any;
  classes: any;
  attrs: Record<string, any>;
  options: { label: string; value: any }[];
}

interface Props {
  context: Context;
}

const props = defineProps<Props>();

const appStore = useAppStore();
const selectedOption = computed(() =>
  props.context.options.find((option) => option.value === props.context._value)
);
</script>
