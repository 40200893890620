import { router } from "@src/router";
import AuthService from "@src/services/auth.service";
import AgencyService from "@src/services/agency.service";
import { LoginData } from "@src/types/services/auth.service";
import { HTTPError } from "ky";
import { useAuthStore } from "./auth.store";
import { Step } from "@src/types/stores/login.store";

export const useLoginStore = defineStore("login", () => {
  const authStore = useAuthStore();
  /*** State */
  const step = ref<Step>("email");

  const initialData = {
    email: "",
    password: "",
  };

  const data = reactive<LoginData>({ ...initialData });
  const loginError = ref<string>();

  /*** Actions */
  async function lookup(_data: any) {
    Object.assign(data, _data);
    if (!router.currentRoute.value.query.agency) {
      authStore.agency = await AgencyService.lookup(data.email);
    }
    step.value = "password";
    return true;
  }

  async function login(_data: any, _agency?: string) {
    Object.assign(data, _data);
    try {
      loginError.value = undefined;

      const agency = _agency ?? authStore.agency?.slug;

      await AuthService.login(data, agency ? { agency } : {});

      if (authStore.intended) {
        router.push(authStore.intended);
        authStore.intended = undefined;
      } else {
        router.push({
          name: "dashboard",
          query: router.currentRoute.value.query,
        });
      }
    } catch (e) {
      if (e instanceof HTTPError) {
        const data = await e.response.clone().json();
        switch (e.response.status) {
          case 401:
            if (data.errors.message) {
              loginError.value = data.errors.message;
            }
            break;
          case 400:
            break;
          default:
            throw e;
        }
      } else {
        throw e;
      }
    }
  }

  function reset() {
    step.value = "email";
    Object.assign(data, initialData);
  }

  return {
    data,
    loginError,
    step,
    lookup,
    login,
    reset,
  };
});
