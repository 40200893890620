export const createYnalpExtra = function (extraName: string): Element {
    let ynalpExtraRoot = document.querySelector('#ynalp-extra');
    if (!ynalpExtraRoot) {
        ynalpExtraRoot = document.createElement("div");
        ynalpExtraRoot.id = "ynalp-extra";
        document.body.appendChild(ynalpExtraRoot);
    }
    
    let ynalpExtra = ynalpExtraRoot.querySelector(`#${extraName}`)
    if (!ynalpExtra) {
        ynalpExtra = document.createElement('div');
        ynalpExtra.id = extraName;
        ynalpExtraRoot.appendChild(ynalpExtra);
    }

    return ynalpExtra;
}