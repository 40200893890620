/* eslint-disable eqeqeq */
import { FormKitValidationMessages } from "@formkit/validation";

/**
 * Here we can import additional helper functions to assist in formatting our
 * language. Feel free to add additional helper methods to libs/formats if it
 * assists in creating good validation messages for your locale.
 */
import {
  sentence as s,
  list,
  date,
  order,
  FormKitLocaleMessages,
} from "@formkit/i18n";
import mime from "mime";
import { uploadMaxFilesize } from "@config/media";

export const ui: FormKitLocaleMessages = {
  /**
   * Shown on buttons for adding new items.
   */
  add: "Añadir",
  /**
   * Shown when a button to remove items is visible.
   */
  remove: "Quitar",
  /**
   * Shown when there are multiple items to remove at the same time.
   */
  removeAll: "Quitar todos",
  /**
   * Shown when all fields are not filled out correctly.
   */
  incomplete: "Discúlpe, los campos no fueron completados correctamente.",
  /**
   * Shown in a button inside a form to submit the form.
   */
  submit: "Enviar",
  /**
   * Shown when no files are selected.
   */
  noFiles: "Archivo no seleccionado",
  /**
   * Shown on buttons that move fields up in a list.
   */
  moveUp: "Moverse hacia arriba",
  /**
   * Shown on buttons that move fields down in a list.
   */
  moveDown: "Moverse hacia abajo",
  /**
   * Shown when something is actively loading.
   */
  isLoading: "Cargando...",
  /**
   * Shown when there is more to load.
   */
  loadMore: "Cargar más",
};

/**
 * These are all the possible strings that pertain to validation messages.
 * @public
 */
export const validation: FormKitValidationMessages = {
  /**
   * The value is not an accepted value.
   * @see {@link https://docs.formkit.com/essentials/validation#accepted}
   */
  accepted({ name }): string {
    /* <i18n case="Shown when the user-provided value is not a valid 'accepted' value."> */
    return `Acepte el ${name} por favor.`;
    /* </i18n> */
  },

  /**
   * The date is not after
   * @see {@link https://docs.formkit.com/essentials/validation#date-after}
   */
  date_after({ name, args }) {
    if (Array.isArray(args) && args.length) {
      /* <i18n case="Shown when the user-provided date is not after the date supplied to the rule."> */
      return `${s(name)} debe ser posterior a ${date(args[0])}.`;
      /* </i18n> */
    }
    /* <i18n case="Shown when the user-provided date is not after today's date, since no date was supplied to the rule."> */
    return `${s(name)} debe ser una fecha futura.`;
    /* </i18n> */
  },

  /**
   * The value is not a letter.
   * @see {@link https://docs.formkit.com/essentials/validation#alpha}
   */
  alpha({ name }) {
    /* <i18n case="Shown when the user-provided value contains non-alphabetical characters."> */
    return `${s(name)} debe contener solo caractéres alfabéticos.`;
    /* </i18n> */
  },

  /**
   * The value is not alphanumeric
   * @see {@link https://docs.formkit.com/essentials/validation#alphanumeric}
   */
  alphanumeric({ name }) {
    /* <i18n case="Shown when the user-provided value contains non-alphanumeric characters."> */
    return `${s(name)} debe ser alfanumérico.`;
    /* </i18n> */
  },

  /**
   * The value is not letter and/or spaces
   * @see {@link https://docs.formkit.com/essentials/validation#alpha-spaces}
   */
  alpha_spaces({ name }) {
    /* <i18n case="Shown when the user-provided value contains non-alphabetical and non-space characters."> */
    return `${s(name)} espacios alfa solo pueden contener letras y espacios.`;
    /* </i18n> */
  },

  /**
   * The date is not before
   * @see {@link https://docs.formkit.com/essentials/validation#date-before}
   */
  date_before({ name, args }) {
    if (Array.isArray(args) && args.length) {
      /* <i18n case="Shown when the user-provided date is not before the date supplied to the rule."> */
      return `${s(name)} debe ser anterior a ${date(args[0])}.`;
      /* </i18n> */
    }
    /* <i18n case="Shown when the user-provided date is not before today's date, since no date was supplied to the rule."> */
    return `${s(name)} debe ser una fecha pasada.`;
    /* </i18n> */
  },

  /**
   * The value is not between two numbers
   * @see {@link https://docs.formkit.com/essentials/validation#between}
   */
  between({ name, args }) {
    if (isNaN(args[0]) || isNaN(args[1])) {
      /* <i18n case="Shown when any of the arguments supplied to the rule were not a number."> */
      return `El campo no fue completado correctamente y no puede ser enviado.`;
      /* </i18n> */
    }
    const [a, b] = order(args[0], args[1]);
    /* <i18n case="Shown when the user-provided value is not between two numbers."> */
    return `${s(name)} debe estar entre ${a} y ${b}.`;
    /* </i18n> */
  },

  /**
   * The confirmation field does not match
   * @see {@link https://docs.formkit.com/essentials/validation#confirm}
   */
  confirm({ name }) {
    /* <i18n case="Shown when the user-provided value does not equal the value of the matched input."> */
    return `${s(name)} no coincide.`;
    /* </i18n> */
  },

  /**
   * The value is not a valid date
   * @see {@link https://docs.formkit.com/essentials/validation#date-format}
   */
  date_format({ name, args }) {
    if (Array.isArray(args) && args.length) {
      /* <i18n case="Shown when the user-provided date does not satisfy the date format supplied to the rule."> */
      return `${s(name)} no es una fecha válida, por favor utilice el formato ${
        args[0]
      }`;
      /* </i18n> */
    }
    /* <i18n case="Shown when no date argument was supplied to the rule."> */
    return "El campo no fue completado correctamente y no puede ser enviado.";
    /* </i18n> */
  },

  /**
   * Is not within expected date range
   * @see {@link https://docs.formkit.com/essentials/validation#date-between}
   */
  date_between({ name, args }) {
    /* <i18n case="Shown when the user-provided date is not between the start and end dates supplied to the rule. "> */
    return `${s(name)} debe estar entre ${date(args[0])} y ${date(args[1])}`;
    /* </i18n> */
  },

  /**
   * Shown when the user-provided value is not a valid email address.
   * @see {@link https://docs.formkit.com/essentials/validation#email}
   */
  email: "Ingrese una dirección de correo electrónico válida por favor.",

  /**
   * Does not end with the specified value
   * @see {@link https://docs.formkit.com/essentials/validation#ends-with}
   */
  ends_with({ name, args }) {
    /* <i18n case="Shown when the user-provided value does not end with the substring supplied to the rule."> */
    return `${s(name)} no termina con ${list(args)}.`;
    /* </i18n> */
  },

  /**
   * Is not an allowed value
   * @see {@link https://docs.formkit.com/essentials/validation#is}
   */
  is({ name }) {
    /* <i18n case="Shown when the user-provided value is not one of the values supplied to the rule."> */
    return `${s(name)} no es un valor permitido.`;
    /* </i18n> */
  },

  /**
   * Does not match specified length
   * @see {@link https://docs.formkit.com/essentials/validation#length}
   */
  length({ name, args: [first = 0, second = Infinity] }) {
    const min = Number(first) <= Number(second) ? first : second;
    const max = Number(second) >= Number(first) ? second : first;
    if (min == 1 && max === Infinity) {
      /* <i18n case="Shown when the length of the user-provided value is not at least one character."> */
      return `${s(name)} debe tener al menos una letra.`;
      /* </i18n> */
    }
    if (min == 0 && max) {
      /* <i18n case="Shown when first argument supplied to the rule is 0, and the user-provided value is longer than the max (the 2nd argument) supplied to the rule."> */
      return `${s(name)} debe tener como máximo ${max} caractéres.`;
      /* </i18n> */
    }
    if (min === max) {
      /* <i18n case="Shown when first and second argument supplied to the rule are the same, and the user-provided value is not any of the arguments supplied to the rule."> */
      return `${s(name)} debe tener ${max} caracteres.`;
      /* </i18n> */
    }
    if (min && max === Infinity) {
      /* <i18n case="Shown when the length of the user-provided value is less than the minimum supplied to the rule and there is no maximum supplied to the rule."> */
      return `${s(name)} debe tener como mínimo ${min} caractéres.`;
      /* </i18n> */
    }
    /* <i18n case="Shown when the length of the user-provided value is between the two lengths supplied to the rule."> */
    return `${s(name)} debe tener entre ${min} y ${max} caractéres.`;
    /* </i18n> */
  },

  /**
   * Value is not a match
   * @see {@link https://docs.formkit.com/essentials/validation#matches}
   */
  matches({ name }) {
    /* <i18n case="Shown when the user-provided value does not match any of the values or RegExp patterns supplied to the rule. "> */
    return `${s(name)} no es un valor permitido.`;
    /* </i18n> */
  },

  /**
   * Exceeds maximum allowed value
   * @see {@link https://docs.formkit.com/essentials/validation#max}
   */
  max({ name, node: { value }, args }) {
    if (Array.isArray(value)) {
      /* <i18n case="Shown when the length of the array of user-provided values is longer than the max supplied to the rule."> */
      return `No puede tener más de ${args[0]} ${name}.`;
      /* </i18n> */
    }
    /* <i18n case="Shown when the user-provided value is greater than the maximum number supplied to the rule."> */
    return `${s(name)} debe ser menor o igual a ${args[0]}.`;
    /* </i18n> */
  },

  /**
   * The (field-level) value does not match specified mime type
   * @see {@link https://docs.formkit.com/essentials/validation#mime}
   */
  mime({ name, args }) {
    if (!args[0]) {
      /* <i18n case="Shown when no file formats were supplied to the rule."> */
      return "No existen formatos de archivos permitidos.";
      /* </i18n> */
    }
    /* <i18n case="Shown when the mime type of user-provided file does not match any mime types supplied to the rule."> */
    return `${s(name)} debe ser del tipo: ${args[0]}`;
    /* </i18n> */
  },

  /**
   * Does not fulfill minimum allowed value
   * @see {@link https://docs.formkit.com/essentials/validation#min}
   */
  min({ name, node: { value }, args }) {
    if (Array.isArray(value)) {
      /* <i18n case="Shown when the length of the array of user-provided values is shorter than the min supplied to the rule."> */
      return `No puede tener menos de ${args[0]} ${name}.`;
      /* </i18n> */
    }
    /* <i18n case="Shown when the user-provided value is less than the minimum number supplied to the rule."> */
    return `${s(name)} debe ser de al menos ${args[0]}.`;
    /* </i18n> */
  },

  /**
   * Is not an allowed value
   * @see {@link https://docs.formkit.com/essentials/validation#not}
   */
  not({ name, node: { value } }) {
    /* <i18n case="Shown when the user-provided value matches one of the values supplied to (and thus disallowed by) the rule."> */
    return `“${value}” no es un valor permitido de ${name}.`;
    /* </i18n> */
  },

  /**
   *  Is not a number
   * @see {@link https://docs.formkit.com/essentials/validation#number}
   */
  number({ name }) {
    /* <i18n case="Shown when the user-provided value is not a number."> */
    return `${s(name)} debe ser un número.`;
    /* </i18n> */
  },

  /**
   * Required field.
   * @see {@link https://docs.formkit.com/essentials/validation#required}
   */
  required({ name }) {
    /* <i18n case="Shown when a user does not provide a value to a required input."> */
    return `${s(name)} es requerido.`;
    /* </i18n> */
  },

  /**
   * Does not start with specified value
   * @see {@link https://docs.formkit.com/essentials/validation#starts-with}
   */
  starts_with({ name, args }) {
    /* <i18n case="Shown when the user-provided value does not start with the substring supplied to the rule."> */
    return `${s(name)} debe comenzar con ${list(args)}.`;
    /* </i18n> */
  },

  /**
   * Is not a url
   * @see {@link https://docs.formkit.com/essentials/validation#url}
   */
  url() {
    /* <i18n case="Shown when the user-provided value is not a valid url."> */
    return `Proporcione una URL válida por favor.`;
    /* </i18n> */
  },

  phone() {
    /* <i18n case="Shown when a user does not provide a valid phone number."> */
    return `Número de teléfono no válido.`;
    /* </i18n> */
  },

  hasLowerAndUppercase({ name }) {
    /* <i18n case="Shown when a field doesn't have lowercase and uppercase characters."> */
    return `El campo ${name} debe contener al menos una letra minúscula y una letra mayúscula.`;
    /* </i18n> */
  },

  hasNumber({ name }) {
    /* <i18n case="Shown when a field doesn't have number"> */
    return `El campo ${name} debe contener al menos un número.`;
    /* </i18n> */
  },

  hasSpecial({ name }) {
    /* <i18n case="Shown when a field doesn't have special character"> */
    return `El campo ${name} debe contener al menos un carácter especial.`;
    /* </i18n> */
  },

  mimes({ args, name, node }) {
    const isMultiple = !!node.props.attrs?.multiple;
    const accept = Array.from((args[0] ?? []) as string[])
      .map((value: string) => mime.getExtension(value) ?? value)
      .join(", ");
    return isMultiple
      ? `Los archivos proporcionados en el campo ${name} deben ser del tipo: ${accept}`
      : `El archivo proporcionado en el campo ${name} debe ser del tipo: ${accept}`;
  },

  integer({ name }) {
    return `El campo ${name} debe ser un número entero.`;
  },

  fileSize({ args, name, node }) {
    const isMultiple = !!node.props.attrs?.multiple;
    const maxFileSize = args[0] ?? uploadMaxFilesize;
    return isMultiple
      ? `Los archivos proporcionados en el campo ${name} son demasiado grandes. (Máximo ${maxFileSize})`
      : `El archivo proporcionado en el campo ${name} es demasiado grande. (Máximo ${maxFileSize})`;
  },
};
