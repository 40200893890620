<template>
  <component
    :is="actionComponent"
    :data="requiredAction?.data"
    @callback="onCallback"
    @destroy="onDestroy"
  />
</template>

<script setup lang="ts">
import { ActionFunction } from "@src/types/actions/types";
import { actionsBus, actionsCallbackBus } from "./ActionsBus";
import { account } from "./account";
import { RequiredAction } from "@src/types/services/responses";

const actions: Record<string, () => Promise<ActionFunction>> = {
  ...Object.fromEntries(
    Object.entries(account).map(([key, value]) => [
      `account.${key}`,
      value as () => Promise<ActionFunction>,
    ])
  ),
};

const actionComponent = shallowRef();
const requiredAction = ref<RequiredAction>();

async function onAction(action: RequiredAction) {
  const code = action.code;
  if (code && actions[code]) {
    requiredAction.value = action;
    const actionFunction = await actions[code]();
    actionFunction(actionComponent, action.data);
  } else {
    throw new Error(`Action doesn't exist : ${action}`);
  }
}

actionsBus.on(onAction);

function onCallback(data?: any) {
  actionsCallbackBus.emit({ code: `${requiredAction.value?.code}`, data });
}

function onDestroy() {
  actionComponent.value = undefined;
  requiredAction.value = undefined;
}
</script>
