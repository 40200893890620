import { BeforeRequestHook } from "ky";
import nProgress from "nprogress";
import langHook from "./lang";
import xsrfHook from "./xsrf";

const beforeRequestHooks: BeforeRequestHook[] = [
  () => {
    nProgress.start();
  },
  langHook,
  xsrfHook,
];

export default beforeRequestHooks;
