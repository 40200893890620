import { useFuse } from "@vueuse/integrations/useFuse";
import { Ref } from "vue";
import { PlanySelectFlattedOption } from "./PlanySelect.vue";

export function useSearch(options: Ref<PlanySelectFlattedOption[]>, q: Ref<string>) {

  const removeGroupOptions = computed(() =>
    options.value.filter((value) => !value.title)
  );
  const { results } = useFuse(q, removeGroupOptions, {
    fuseOptions: {
      keys: ["label"],
      threshold: 0.3
    },
  });

  return results;
}
