import { base as inputBase } from "./base";

const base: Record<string, string> = {
  ...inputBase,
  inner: "$reset relative min-h-12 h-auto flex items-center px-0",
  input: "$reset range range-xs range-secondary block",
};

Object.entries(base).forEach((value: [keyof typeof base, string]) => {
  const key = value[0];
  const val = value[1];

  base[key] += `${val}`;
});

export default base;
