import {
  AcceptTimeClockingData,
  GetSubscriptionsResponse,
  LegacyAccount,
  LoginData,
  LostPasswordData,
  MeResponse,
  ProfilePicture,
  RegisterData,
  RegisterResponse,
  ResendValidateEmailResponse,
  ResetPasswordData,
  SetPicturesResponse,
  SetProfilePictureResponse,
  SubscriptionAgency,
  UpdatePasswordData,
  UpdateProfileData,
  User,
} from "@src/types/services/auth.service";
import { ErrorResponse } from "@src/types/services/responses";
import { HTTPError, SearchParamsOption } from "ky";
import { fetch } from "./fetch";

export default class AuthService {
  public static async getCSRF() {
    await fetch.get("sanctum/csrf-cookie");
  }

  public static async me(params?: SearchParamsOption) {
    const response = await fetch.get("accounts/me", {
      searchParams: params,
    });
    const data: MeResponse = await response.clone().json();

    return new User(data.data);
  }

  public static async login(
    data: LoginData,
    searchParams?: SearchParamsOption
  ) {
    await fetch.post("login", {
      searchParams,
      json: {
        ...data,
        cookie: true,
      },
    });
  }

  public static async logout() {
    await fetch.post("logout");
  }

  public static async legacyHash(
    hash: string
  ): Promise<{ data: LegacyAccount }> {
    const response = await fetch.get(`accounts/${hash}`);
    return await response.clone().json();
  }

  public static async register(
    json: RegisterData,
    searchParams?: SearchParamsOption
  ): Promise<RegisterResponse> {
    const response = await fetch.post("accounts/sign-up", {
      searchParams,
      json,
    });
    return await response.clone().json();
  }

  public static async validateEmail(json: { activation_code: string }) {
    return await fetch.post("accounts/activation", {
      json,
    });
  }

  public static async resendValidateEmail(): Promise<ResendValidateEmailResponse> {
    const response = await fetch.post("accounts/activation/reset");
    return await response.clone().json();
  }

  public static async updatePassword(json: UpdatePasswordData) {
    await fetch.post("accounts/update-password", {
      json,
    });
  }

  public static async lostPassword(json: LostPasswordData): Promise<boolean> {
    await fetch.post("reset-password", {
      json,
    });
    return true;
  }

  public static async resetPassword(
    json: ResetPasswordData
  ): Promise<boolean | ErrorResponse> {
    try {
      await fetch.post("reset-password/new", {
        json,
      });
      return true;
    } catch (e) {
      if (e instanceof HTTPError && e.response.status === 409) {
        return (await e.response.json()) as ErrorResponse;
      }
      throw e;
    }
  }

  public static async setProfilePicture(json: {
    file: string;
  }): Promise<SetProfilePictureResponse> {
    const response = await fetch.put("accounts/profile-picture", {
      json,
    });
    return await response.clone().json();
  }

  public static async setPictures(
    pictures: ProfilePicture[]
  ): Promise<SetPicturesResponse> {
    const response = await fetch.put("accounts/pictures", {
      json: {
        pictures,
      },
    });
    return await response.clone().json();
  }

  public static async getSubscriptions<T = SubscriptionAgency>(
    searchParams?: SearchParamsOption
  ): Promise<GetSubscriptionsResponse<T>> {
    const response = await fetch.get("accounts/subscriptions", {
      searchParams,
    });
    const json = await response.clone().json();
    return {
      data: json.data.map((subscription: any) => ({
        ...subscription,
        registered_since: dayjs(subscription.registered_since, "YYYY-MM-DD"),
      })),
    };
  }

  public static async acceptTimeClocking(json: AcceptTimeClockingData) {
    await fetch.post("candidates/time-clocking", {
      json,
    });

    return true;
  }

  public static async updateProfile(json: Partial<UpdateProfileData>) {
    await fetch.put("accounts/profile", {
      json,
    });
    return true;
  }

  public static async emailUpdateValidation(token: string) {
    await fetch.post(`accounts/profile/email-update-validation/${token}`);
    return true;
  }

  public static async deleteLink(email: string) {
    await fetch.post("accounts/delete-link", {
      json: {
        email,
      },
    });
    return true;
  }

  public static async delete(json: { email: string; token: string }) {
    await fetch.delete("accounts", {
      json,
    });
    return true;
  }
}
