import { BeforeErrorHook } from "ky";
import nProgress from "nprogress";
import actionsHook from "./actions";
import fatalErrorHook from "./fatalError";

const beforeErrorHooks: BeforeErrorHook[] = [
  (error) => {
    nProgress.done();
    return error;
  },
  fatalErrorHook,
  actionsHook,
];

export default beforeErrorHooks;
