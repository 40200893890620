import { router } from "@src/router";
import { Plugin } from "vue";

const intervalMS = 60 * 60 * 1000;

async function fetchSw(swUrl: string, r: ServiceWorkerRegistration) {
  if (!(!r.installing && navigator)) return;

  if ("connection" in navigator && !navigator.onLine) return;

  const resp = await fetch(swUrl, {
    cache: "no-store",
    headers: {
      cache: "no-store",
      "cache-control": "no-cache",
    },
  });

  try {
    if (resp?.status === 200) await r.update();
  } catch (e) {}
}

export const pwaPlugin: Plugin = {
  install() {
    router
      .isReady()
      .then(async () => {
        const { registerSW } = await import("virtual:pwa-register");
        registerSW({
          immediate: true,
          onRegisteredSW(swUrl, r) {
            if (r) {
              fetchSw(swUrl, r);
              setInterval(() => fetchSw(swUrl, r), intervalMS);
            }
          },
        });
      })
      .catch(() => {});
  },
};
