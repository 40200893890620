import { Ref } from "vue";
import {
  PlanySelectProps,
  PlanySelectOptions,
  PlanySelectComputedOption,
  PlanySelectComputedGroupOption,
} from "./PlanySelect.vue";

export function useOptions(props: PlanySelectProps, q: Ref<string>) {
  const rawOptions = ref<PlanySelectOptions>([]);
  const loading = ref<boolean>(false);
  const resolveTimeout = ref();
  const firstResults = ref(false);

  watch(
    () => props.options,
    async (_options) => {
      if (typeof _options === "function") {
        if (props.resolveOnLoad) {
          let result = _options(q.value);
          if (result instanceof Promise) {
            loading.value = true;
            result = await result;
            loading.value = false;
          }
          rawOptions.value = result;
          firstResults.value = true;
        }
      } else if (Array.isArray(_options)) {
        rawOptions.value = _options;
        firstResults.value = true;
      }
    },
    {
      immediate: true,
      deep: true,
    }
  );

  watch(q, (q) => {
    clearTimeout(resolveTimeout.value);

    if (
      typeof props.options === "function" &&
      props.filterManual &&
      q.length >= (props.filterMin ?? 0)
    ) {
      loading.value = true;
      resolveTimeout.value = setTimeout(async () => {
        let result = (
          props.options as (
            q?: string
          ) => PlanySelectOptions | Promise<PlanySelectOptions>
        )(q);

        if (result instanceof Promise) result = await result;

        rawOptions.value = result;
        firstResults.value = true;
        loading.value = false;
        resolveTimeout.value = undefined;
      }, props.filterDelay ?? 0);
    } else {
      loading.value = false;
    }
  });

  const options = computed(() => {
    let options: Array<
      PlanySelectComputedOption | PlanySelectComputedGroupOption
    > = [];

    if (Array.isArray(rawOptions.value) && rawOptions.value.length) {
      if (
        typeof rawOptions.value[0] === "object" &&
        !Array.isArray(rawOptions.value[0])
      ) {
        if (!props.labelKey || !props.valueKey) return [];

        if (props.group && rawOptions.value[0].options) {
          options = (unref(rawOptions) as Record<string, any>[]).map(
            (option) => {
              const _option = {
                label: option[props.groupLabelKey!],
                options: option.options.map(
                  (_subOption: Record<string, any>) => ({
                    label: _subOption[props.labelKey!],
                    value: props.object
                      ? _subOption
                      : _subOption[props.valueKey!],
                  })
                ),
              };
              return _option;
            }
          );
        } else {
          options = (unref(rawOptions) as Record<string, any>[]).map(
            (option) => ({
              label: option[props.labelKey!],
              value: props.object ? option : option[props.valueKey!],
            })
          );
        }
      } else {
        options = unref(rawOptions).map((option: any) => ({
          label: option,
          value: option,
        }));
      }
    }

    if (props.addEmptyOption) {
      options.unshift({
        label: props.placeholder ?? "",
        value: undefined,
        isEmpty: true,
      });
    }

    return options;
  });

  function addOption(value: string) {
    const _raw = unref(rawOptions);

    if (Array.isArray(_raw)) {
      if (
        typeof rawOptions.value[0] === "object" &&
        !Array.isArray(rawOptions.value[0])
      ) {
        _raw.push({
          [`${props.labelKey}`]: value,
          [`${props.valueKey}`]: value,
        } as any);
      } else if (typeof rawOptions.value[0] === "string") {
        _raw.push(value as any);
      } else {
        _raw.push({
          [`${props.labelKey}`]: value,
          [`${props.valueKey}`]: value,
        } as any);
      }
    }
  }

  return {
    options,
    rawOptions,
    loading,
    firstResults,
    addOption,
  };
}
