<template>
  <div
    class="w-full absolute inset-0"
    :style="{ top, left, bottom, right }"
  >
    <router-view v-slot="{ Component }">
      <transition
        :enter-active-class="`${route.meta.enterActiveClass ?? 'animate-fade'
          } animate-duration-transition animate-ease-in-out`"
        :leave-active-class="`${route.meta.leaveActiveClass ?? 'animate-fade'
          } animate-reverse animate-duration-transition animate-ease-in-out`"
      >
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <ActionsManager />
  <YModal
    :open="!online"
    :backdrop-close="false"
    :close-button="false"
    modal-class="space-y-4"
  >
    <FaDuotoneWifiSlash class="text-6xl text-error-content dark:text-error" />
    <h1>{{ $t("offline.title") }}</h1>
    <p>{{ $t("offline.text") }}</p>
  </YModal>
  <div
    v-if="isPulling || isRefreshing"
    class="fixed -top-5 left-0 right-0 z-[9999999] w-full flex justify-center items-center"
  >
    <div
      class="bg-base-content text-base-100 w-10 h-10 rounded-full flex justify-center items-center origin-center"
      :style="refreshIconContainerStyle"
    >
      <FaRegularRotateRight :style="refreshIconStyle" />
    </div>
  </div>
  <AskDeleteModal
    v-model:open="authStore.deleteAccountOpen"
    :email="authStore.user?.email"
  />
  <SupportModal v-model:open="supportModalOpen" />
</template>

<script lang="ts">
export const installPromptEventSymbol: InjectionKey<
  Ref<BeforeInstallPromptEvent | undefined>
> = Symbol("INSTALL-PROMPT-EVENT");

export const supportInstallSymbol: InjectionKey<Ref<boolean>> = Symbol(
  "SUPPORT-INSTALL-SYMBol"
);
</script>

<script setup lang="ts">
import { useAppStore } from "./store/app.store";
import ActionsManager from "./actions/ActionsManager.vue";
import { configSymbol } from "@formkit/vue";
import { setI18nLocale } from "./i18n";
import { isFirefox, isSafari } from "./utils/userAgent";
import { usePullToRefresh } from "./composables/usePullToRefresh";
import { useAuthStore } from "./store/auth.store";
import { supportModalSymbol } from "./types/global";

const appStore = useAppStore();
const authStore = useAuthStore();
const route = useRoute();

onMounted(async () => {
  setI18nLocale(appStore.locale);
});

const config = inject(configSymbol);

watchEffect(() => {
  if (config) {
    config.locale = appStore.locale;
  }
});

const { top, right, bottom, left } = useScreenSafeArea();

const installPromptEvent = ref<BeforeInstallPromptEvent>();

onBeforeMount(() => {
  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    installPromptEvent.value = e as BeforeInstallPromptEvent;
  });
});

const supportInstall = computed(
  () => !!installPromptEvent.value || isSafari() || isFirefox()
);

const online = useOnline();

const { isPulling, isRefreshing, length } = usePullToRefresh(
  document.body,
  () => {
    window.location.reload();
  }
);

const refreshIconContainerStyle = computed(() => ({
  transform: `translateY(${length.value / 2}px)`,
  opacity: `${(length.value * 1.2) / 100}`,
}));
const refreshIconStyle = computed(() => ({
  transform: `rotate(${length.value * 2}deg)`,
}));

const supportModalOpen = ref(false);

provide(installPromptEventSymbol, installPromptEvent);
provide(supportInstallSymbol, supportInstall);
provide(supportModalSymbol, supportModalOpen);
</script>
