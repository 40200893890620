import { v4 as uuidv4 } from 'uuid';
import { Variant } from '../components/YAlert';
import { Size } from '../sizes';

export const positions = ["top-left", "top-right", "top-center", "bottom-left", "bottom-right", "bottom-center"]
export type Position = typeof positions[number];

export const toastPositionClasses: { [K in Position]: string } = {
    "top-left": "y-toaster--top-left",
    "top-right": "y-toaster--top-right",
    "top-center": "y-toaster--top-center",
    "bottom-left": "y-toaster--bottom-left",
    "bottom-right": "y-toaster--bottom-right",
    "bottom-center": "y-toaster--bottom-center"
}

export interface Toast {
    uuid: string,
    title: string,
    duration: number,
    position: Position,
    variant: Variant,
    size: Size,
    dismissible: boolean,
    icon: boolean
}
export const defaultValue = (): Readonly<Partial<Toast>> => ({
    uuid: uuidv4(),
    duration: 3000,
    position: "top-right",
    variant: "neutral",
    size: "sm",
    dismissible: true,
    icon: false
});
export type Config = Partial<Toast> | string;
export type ToastFunction = (config: Config) => void;
export type List = Toast[];
export type ByPosition = { [K in Position]?: List };

