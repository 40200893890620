import { useAuthStore } from "@src/store/auth.store";
import { RouteMiddleware } from "@src/types/router";

const fromBlacklist = ["login", "register", "validate-email"];

export const reloadMeMiddleware: RouteMiddleware = async (to, from) => {
  if (
    to.name !== from.name &&
    from.name &&
    !fromBlacklist.includes(from.name.toString())
  ) {
    const authStore = useAuthStore();
    await authStore.me();
  }
};
