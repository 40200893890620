import {
  PlanySelectProps,
  PlanySelectEmits,
  PlanySelectFlattedOption,
} from "./PlanySelect.vue";

export function useData(
  props: PlanySelectProps,
  emits: PlanySelectEmits,
  flatted: Ref<PlanySelectFlattedOption[]>
) {
  const vmodel = useVModel(props, "modelValue", emits, {
    passive: true,
  });

  function deleteOption(index: number) {
    if (props.mode === "tags") {
      if (Array.isArray(vmodel.value)) {
        const newVmodel = Array.from(vmodel.value);
        newVmodel.splice(index, 1)
        vmodel.value = newVmodel;
      } else vmodel.value = [];
    }
  }

  function toggleOption(option: PlanySelectFlattedOption) {
    if (props.mode === "single") {
      vmodel.value = option.value;
      return;
    }

    if (props.mode === "tags") {
      const newValue = Array.isArray(vmodel.value) ? [...vmodel.value] : [];
      const index = newValue.findIndex((value) => option.value === value);
      if (index >= 0) {
        newValue.splice(index, 1);
      } else {
        newValue.push(option.value);
      }
      vmodel.value = newValue;
      return;
    }
  }

  const selectOptions = computed((): PlanySelectFlattedOption[] => {
    let option;
    switch (props.mode) {
      case "single":
        option = flatted.value.find((option) => option.value === vmodel.value);
        return option ? [option] : [];
      case "tags":
        if (Array.isArray(vmodel.value))
          return Array.from(vmodel.value)
            .map((value) =>
              flatted.value.find((option) => option.value === value)
            )
            .filter((option) => !!option) as PlanySelectFlattedOption[];
        return [];
      default:
        return [];
    }
  });

  return {
    vmodel,
    selectOptions,
    toggleOption,
    deleteOption,
  };
}
