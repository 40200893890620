export function useAsyncHandler<T extends any[], U>(
  handler: (...args: T) => Promise<U>
) {
  const loading = ref(false);
  async function handle(...args: T): Promise<U> {
    loading.value = true;
    const returnValue = await handler(...args);
    loading.value = false;
    return returnValue;
  }

  return { loading, handle };
}
