<template>
  <div class="alert border border-opacity-20" :class="classes">
    <div class="flex-1 w-full">
      <slot name="icon">
        <Component
          :is="variantIcons[props.variant]"
          v-if="icon"
          class="text-[1.5em] mr-3"
        />
      </slot>
      <label class="w-full">
        <slot></slot>
      </label>
    </div>
    <div v-if="slots.after" class="flex-none ml-2">
      <slot name="after"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  sizeClasses,
  Variant,
  variantClasses,
  variantIcons
} from "@lib/types/components/YAlert";
import { Size } from "@lib/types/sizes";

interface Props {
  icon?: boolean;
  variant?: Variant;
  size?: Size;
}

const props = withDefaults(defineProps<Props>(), {
  icon: true,
  size: "md",
  variant: "neutral"
});

const slots = useSlots();

const classes = computed(() => [
  variantClasses[props.variant],
  sizeClasses[props.size]
]);
</script>
