export type Breakpoint = "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
export const breakpoints: Record<Breakpoint, number> = {
  "2xs": 320,
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export type AnimationClass = { enter: string; leave: string };

export type Gender = 0 | 1;

export interface PaginatedData<T> {
  elements: T[];
  page: number;
  elements_per_page: number;
  total_elements: number;
}

export interface LocalizedData {
  name: any;
  label: string;
}

export interface SimpleData<T> {
  id: T;
  label: string;
}

export const supportModalSymbol: InjectionKey<Ref<boolean>> = Symbol("supportModal");