<template>
    <YModal
        v-model:open="open"
        modal-class="w-full max-w-screen-sm overflow-hidden"
    >
        <div class="space-y-4 w-full">
            <h3>{{ $t("agency_contact_modal.title") }}</h3>
            <p>{{ $t('agency_contact_modal.text') }}</p>
        </div>
        <div
            v-if="evaluating"
            class="py-4 text-6xl text-center"
        >
            <YSpinner />
        </div>
        <ul
            v-else-if="agencies"
            class="menu bg-base-100 w-full space-y-2 mt-4"
        >
            <template
                v-for="agency in agencies"
                :key="agency.id"
            >
                <li v-if="agency.email && agency.email.trim().length">
                    <a
                        :href="`mailto:${agency.email}`"
                        class="bg-base-200 hover:bg-base-300/50 rounded-box"
                        @click="open = false"
                    >
                        {{ agency.label }}
                    </a>
                </li>
            </template>
        </ul>
    </YModal>
</template>

<script setup lang="ts">
import { useAsyncData } from '@src/composables/useAsyncData';
import AuthService from '@src/services/auth.service';

const open = defineModel<boolean>("open", {
    default: false,
    local: true
});

const {
    data: agencies,
    evaluate,
    evaluating,
} = useAsyncData(async () => {
    const { data } = await AuthService.getSubscriptions({ "includes[show_criteria_option]": "" });
    return data;
});

onBeforeMount(evaluate);
</script>