import { Plugin } from "vue";
import { createYnalpExtra } from "@lib/utils/ynalpExtras";
import YModalContainer from "@lib/components/YModalAlert/YModalContainer.vue";
import { EventBusKey, UseEventBusReturn } from "@vueuse/core";
import { Modal } from "@lib/types/plugins/ModalAlertPlugin";

const ModalAlertKey: EventBusKey<Modal> = Symbol("MODAL-ALERT-KEY");
export class ModalAlertBus {
  // eslint-disable-next-line no-use-before-define
  private static _instance: ModalAlertBus;
  private _bus: UseEventBusReturn<Modal, unknown>;

  constructor() {
    this._bus = useEventBus(ModalAlertKey);
  }

  private static get instance(): ModalAlertBus {
    if (!this._instance) this._instance = new ModalAlertBus();

    return this._instance;
  }

  public static get bus(): UseEventBusReturn<Modal, unknown> {
    return ModalAlertBus.instance._bus;
  }

  public static modalAlert(modal: Modal) {
    ModalAlertBus.bus.emit(modal);
  }
}

export const modalAlertPlugin: Plugin = {
  install(app) {
    const modalContainer = createYnalpExtra("y-modal-alert");
    const modalApp = createApp(YModalContainer);
    modalApp.mount(modalContainer);
    app.provide("$modalAlert", ModalAlertBus.modalAlert);
    app.config.globalProperties.$modalAlert = ModalAlertBus.modalAlert;
  },
};
