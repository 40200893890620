import tippy, { Props, Instance } from "tippy.js";
import { MaybeElementRef, MaybeRef } from "@vueuse/core";


export function useTippy(target: MaybeElementRef, options?: MaybeRef<Partial<Props> | undefined>) {
    const instance = ref<Instance>();

    function init() {
        const _target = unrefElement(target);
        const _options = unref(options);
        
        if(!_target && instance.value) {
            instance.value.destroy();
            instance.value = undefined;
        } else if (_target && !instance.value) {
            instance.value = tippy(_target, _options);
        } else if (instance.value && _options) {
            instance.value.setProps(_options);
        }
    }

    const stopWatchTarget = watch(() => unref(target), init, { deep: true });
    const StopWatchOptions = watch(() => unref(options), init, { deep: true });

    init();

    tryOnBeforeUnmount(() => {
        stopWatchTarget();
        StopWatchOptions();
        instance.value?.destroy();
    });

    return instance;
}