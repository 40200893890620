<template>
    <YModal
        v-model:open="open"
        modal-class="w-full max-w-screen-sm text-center overflow-hidden"
    >
        <div class="space-y-4 w-full">
            <h2>{{ $t("links.support") }}</h2>
            <ul class="menu w-full rounded-box p-1 -m-1 space-y-2">
                <li>
                    <a
                        href="#"
                        class="bg-base-200 hover:bg-base-300"
                        @click.prevent="deleteAccount"
                    >
                        <FaDuotoneTrash class="text-error-content dark:text-error" />
                        {{ $t("delete_account.button") }}
                    </a>
                </li>
                <li>
                    <a
                        href="#"
                        class="bg-base-200 hover:bg-base-300"
                        @click.prevent="fusion"
                    >
                        <FaDuotonePeopleArrows class="text-blue-500 dark:text-blue-300" />
                        {{ $t("fusion_modal.title") }}
                    </a>
                </li>
                <li v-if="authStore.user">
                    <a
                        href="#"
                        class="bg-base-200 hover:bg-base-300"
                        @click.prevent="agency"
                    >
                        <FaDuotoneBuilding class="text-teal-500 dark:text-teal-300" />
                        {{ $t("agency_contact_modal.title") }}
                    </a>
                </li>
                <li>
                    <a
                        href="#"
                        class="bg-base-200 hover:bg-base-300"
                        @click.prevent="technicalSupport"
                    >
                        <FaDuotoneBug class="text-base-content/50" />
                        {{ $t("technical_support.title") }}
                    </a>
                </li>
            </ul>
        </div>
    </YModal>
    <FusionModal
        v-if="fusionOpen"
        v-model:open="fusionOpen"
    />
    <AgencyContactModal
        v-if="agencyContactOpen"
        v-model:open="agencyContactOpen"
    />
    <TechnicalSupportModal
        v-if="technicalSupportOpen"
        v-model:open="technicalSupportOpen"
        @contact-agency="agency"
    />
</template>

<script setup lang="ts">
import { useAuthStore } from '@src/store/auth.store';

const authStore = useAuthStore();

const open = defineModel<boolean>("open", {
    default: false,
    local: true
});

const fusionOpen = ref(false);
const agencyContactOpen = ref(false);
const technicalSupportOpen = ref(false);

function deleteAccount() {
    open.value = false;
    authStore.deleteAccountOpen = true;
}

function technicalSupport() {
    open.value = false;
    technicalSupportOpen.value = true;
}

function fusion() {
    open.value = false;
    fusionOpen.value = true;
}

function agency() {
    open.value = false;
    agencyContactOpen.value = true;
}
</script>