import { useAppStore } from "@src/store/app.store";
import { RouteMiddleware } from "@src/types/router";

export const themeColorMiddleware: RouteMiddleware = (to) => {
  const appStore = useAppStore();
  if (
    to.meta.themeColors &&
    typeof to.meta.themeColors === "object" &&
    !Array.isArray(to.meta.themeColors)
  ) {
    const themeColors = to.meta.themeColors as { light: string; dark: string };
    appStore.setThemeColors(themeColors.light, themeColors.dark);
    appStore.applyThemeColor();
  } else {
    appStore.setThemeColors();
    appStore.applyThemeColor();
  }
};
