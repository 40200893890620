import { Component } from "vue";

import FaRegularIdCard from "~icons/fa-regular/id-card";
import FaRegularMoneyCheckDollar from "~icons/fa-regular/money-check-dollar";
import FaRegularFileMedical from "~icons/fa-regular/file-medical";
import FaRegularFlag from "~icons/fa-regular/flag";
import FaRegularFileUser from "~icons/fa-regular/file-user";

export const uploadMaxFilesize = "10M";
export const allowedImageTypes = ["image/jpeg", "image/png", "image/webp"];
export const allowedProofTypes = ["image/jpeg", "image/png", "image/webp", "application/pdf"];
export const avatarMaxSize = 512;

export const profilePictureMinSize = 600;
export const profilePictureMaxSize = 3000;
export const profilePictureThumbSuffix = "_350";

export const documentIcons: Record<string, Component> = {
  cv: FaRegularFileUser,
  identity: FaRegularIdCard,
  rib: FaRegularMoneyCheckDollar,
  ss: FaRegularFileMedical,
  visa: FaRegularFlag,
};
