<template>
  <span
    v-tippy="tippy"
    class="ml-2 mr-auto text-lg text-error-content dark:text-error"
    >*</span
  >
</template>

<script setup lang="ts">
import { Props } from "tippy.js";

const { t } = useI18n();

const tippy = ref<Partial<Props>>({
  content: t("required_field"),
});
</script>
