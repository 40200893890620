import { tryOnBeforeUnmount } from "@vueuse/shared";
import localforage from "localforage";
import { ref, shallowRef, watch } from "vue";

interface Options<T> {
  defaultValue?: T;
  initialRead?: boolean;
}

export function useLocalStorageRef<T>(key: string, options: Options<T> = {}) {
  const { defaultValue, initialRead = true } = options;

  const data = shallowRef<T | undefined>();
  const reading = ref(false);
  const writing = ref(false);

  async function read() {
    reading.value = true;
    try {
      const result = await localforage.getItem<T>(key);
      data.value = result ?? undefined;
    } catch (e) {
      //
    }
    reading.value = false;
    return data.value;
  }

  async function write(value: T | undefined) {
    writing.value = true;
    try {
      if (value === undefined) {
        await localforage.removeItem(key);
      } else {
        await localforage.setItem(key, value);
      }
    } catch (e) {
      //
    }
    writing.value = false;
  }

  async function init() {
    if (initialRead) await read();
    if (defaultValue !== undefined && data.value === undefined) {
        await write(defaultValue);
        data.value = defaultValue;
    }
  }

  init();

  const stopWatch = watch(data, write, {
    immediate: true,
  });

  tryOnBeforeUnmount(stopWatch);

  return {
    data,
    reading,
    writing,
    read,
    write,
    remove: () => write(undefined),
  };
}
