import { useAuthStore } from "@src/store/auth.store";
import { RouteMiddleware } from "@src/types/router";

export const authMiddleware: RouteMiddleware = async (to) => {
  const authStore = useAuthStore();

  if (to.query.agency) {
    await authStore.setFromAgency(to.query.agency.toString());
  }

  if (!authStore.user) {
    const meResult = await authStore.me();

    if (typeof meResult !== "boolean" && "code" in meResult) return;

    if (!meResult) {
      authStore.intended = to;
      return { ...to, name: "login" };
    }

    const query = { ...to.query };
    delete query.agency;
    return { ...to, query };
  }

  if (to.name === "index") {
    return { ...to, name: "dashboard" };
  }
};
