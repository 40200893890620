<template>
  <teleport to="body">
    <transition
      appear
      enter-active-class="animate-fade animate-duration-transition animate-ease-in-out"
      leave-active-class="animate-fade animate-reverse animate-duration-transition animate-ease-in-out"
    >
      <div
        v-if="isOpen && backdrop"
        ref="backdropEl"
        class="fixed inset-0 bg-base-300 bg-opacity-50 z-[98]"
        :class="backdropClass"
        @click="onBackdropClick"
      ></div>
    </transition>
    <transition
      appear
      :enter-active-class="`${
        isTouch ? 'animate-fade-up' : 'animate-fade'
      } animate-duration-transition animate-ease-in-out`"
      :leave-active-class="`${
        isTouch ? 'animate-fade-up' : 'animate-fade'
      } animate-reverse animate-duration-transition animate-ease-in-out`"
    >
      <div
        v-if="isOpen"
        :id="id"
        ref="modalEl"
        class="bg-base-100 shadow-lg p-6 z-[99] fixed left-1/2 transform -translate-x-1/2 max-h-[calc(var(--vh)*90)] max-w-full overflow-auto flex flex-col items-center justify-start top-1/2 -translate-y-1/2 w-auto h-auto rounded-2xl mx-auto touch:max-h-screen touch:left-0 touch:right-0 touch:bottom-0 touch:transform-none touch:top-auto touch:w-full touch:rounded-b-none"
        :class="modalClass"
        :style="modalStyle"
      >
        <div
          v-if="closeButton"
          class="absolute top-2 right-2"
          @click.prevent="isOpen = false"
        >
          <slot name="close">
            <YButton variant="ghost" size="xs" class="m-1" shape="circle">
              <fa-regular-xmark class="text-lg" />
            </YButton>
          </slot>
        </div>
        <slot></slot>
      </div>
    </transition>
  </teleport>
</template>

<script setup lang="ts">
import { StyleValue } from "vue";
import YButton from "./YButton/YButton.vue";
interface Props {
  id?: any;
  open?: boolean;
  backdrop?: boolean;
  backdropClass?: string | [] | Record<string, unknown>;
  backdropClose?: boolean;
  modalClass?: string | [] | Record<string, unknown>;
  modalStyle?: StyleValue;
  closeButton?: boolean;
}

interface Emits {
  (e: "update:open", value: boolean): void;
}

const props = withDefaults(defineProps<Props>(), {
  backdrop: true,
  backdropClose: true,
  closeButton: true,
});

const emits = defineEmits<Emits>();
const isTouch = useMediaQuery("(pointer: coarse)");
const isOpen = useVModel(props, "open", emits);

function onBackdropClick() {
  if (props.backdropClose) isOpen.value = false;
}

const modalEl = ref<HTMLElement | null>(null);
const backdropEl = ref<HTMLElement | null>(null);

defineExpose({
  modalEl,
  backdropEl,
});
</script>
