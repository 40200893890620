import { FormKitExtendableSchemaRoot, FormKitNode } from "@formkit/core";

export default function datePickerSelectFeature(node: FormKitNode) {
  node.on("created", ({ payload: _node }) => {
    if (
      _node.props?.type === "datepickerSelect" &&
      typeof node.props.definition?.schema === "function"
    ) {
      const definition = { ...node.props.definition };
      const schema = definition.schema as FormKitExtendableSchemaRoot;

      definition.schema = function (extensions = {}) {
        const ext = { ...extensions, ...{ inner: { $el: null } } };
        return schema(ext);
      };
      node.props.definition = definition;
    }
  });
}
