import { actionsBus } from "@src/actions/ActionsBus";
import { ActionResponse } from "@src/types/services/responses";
import { BeforeErrorHook } from "ky";

const actionsHook: BeforeErrorHook = async (error) => {
  const requestUrl = new URL(error.request.url);
  const contentType = error.response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    const data: ActionResponse = await error.response.clone().json();
    if (data?.errors?.required_action?.code) {
      if (
        data.errors.required_action.code ===
          "account.registration.incomplete" &&
        error.request.method === "POST" &&
        requestUrl.pathname.startsWith("/accounts/")
      ) {
        return error;
      }

      actionsBus.emit(data.errors.required_action);
    }
  }
  return error;
};

export default actionsHook;
