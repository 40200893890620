import { FormKitValidationRule } from "@formkit/validation";
import { getUploadMaxFileSize } from "@src/utils/file";
import { uploadMaxFilesize } from "@config/media";

const fileSizeRule: FormKitValidationRule = (node, size = uploadMaxFilesize) =>
  !((node.value ?? []) as { name: string; file: File }[]).find(
    (value) => value.file.size > getUploadMaxFileSize(size)
  );

export default fileSizeRule;
