import { getInst } from "@mobiscroll/javascript";
import { MaybeElementRef, MaybeRef } from "@vueuse/core";

export function useMobiscroll<
  Options extends object,
  ReturnType extends {
    setOptions: (options: Options) => void;
    destroy: () => void;
  },
  Method extends (
    el: HTMLElement,
    options: Options
  ) => ReturnType | { [key: string]: ReturnType }
>(
  target: MaybeElementRef,
  method: Method,
  baseOptions: Options,
  dynamicOptions?: MaybeRef<Options>
) {

  function getInstance() {
    const _el = unref(target);
    const dynamic = unref(dynamicOptions);

    if(_el && _el instanceof HTMLElement) {
      let mobiscroll = getInst<ReturnType>(_el);

      if(!mobiscroll) {
        mobiscroll = method(_el, {
          ...baseOptions,
          ...dynamic
        }) as ReturnType;
      }

      return mobiscroll;
    }
  }

  const stopWatch = watchEffect(() => {
    const dynamic = unref(dynamicOptions);

    const mobiscroll = getInstance();
    
    if(mobiscroll && dynamic) {
      mobiscroll.setOptions(dynamic);
    }
  });

  tryOnBeforeUnmount(stopWatch);

  return getInstance;
}
