import { useFloating, flip, offset, size, autoUpdate } from "@floating-ui/vue";
import { useAppStore } from "@src/store/app.store";
import { StyleValue } from "vue";

export function useDropdown(
  reference: Ref<HTMLElement | null>,
  floating: Ref<HTMLElement | null>
) {
  const appStore = useAppStore();
  const open = ref(false);

  const { x, y, strategy, update } = useFloating(reference, floating, {
    strategy: "fixed",
    placement: "bottom-start",
    middleware: [
      flip({
        fallbackPlacements: ["bottom-start", "top-start"],
      }),
      offset({
        mainAxis: 1,
      }),
      size({
        apply({ availableHeight, elements }) {
          elements.floating.style.maxHeight = `${availableHeight < 267 ? availableHeight : 267}px`;
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { width: referenceWidth } = useElementSize(
    reference,
    {
      width: 0,
      height: 0,
    },
    {
      box: "border-box",
    }
  );

  function checkActiveElement(element: Element) {
    if (
      reference.value !== element &&
      !reference.value?.contains(element) &&
      floating.value !== element &&
      !floating.value?.contains(element)
    ) {
      open.value = false;
    }
  }

  useEventListener("click", (event) => {
    if (event.target instanceof Element && open.value && !appStore.isTouch) {
      checkActiveElement(event.target);
    }
  });


  const style = computed(
    (): StyleValue => ({
      position: strategy.value,
      top: `${y.value ?? 0}px`,
      left: `${x.value ?? 0}px`,
      width: `${referenceWidth.value}px`,
      visibility: `${open.value ? "visible" : "hidden"}`,
    })
  );

  return {
    open,
    style,
    update,
  };
}
