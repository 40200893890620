import { Region } from "@src/types/services/generic.service";
import { fetch } from "./fetch";

export default class GenericService {
  // eslint-disable-next-line no-use-before-define
  private static instance = new GenericService();
  private _memoizedRegions: Record<string, Region[]> = {};

  public static async fetchGeneric<T>(
    name: string,
    base = "generics",
    params?: Record<string, string>
  ): Promise<T> {
    const response = await fetch(`${base}/${name}`, {
      searchParams: params,
    });
    const data: { data: T } = await response.clone().json();
    return data.data;
  }

  public static async getRegionsByCountry(country: string) {
    if (!GenericService.instance._memoizedRegions[country])
      GenericService.instance._memoizedRegions[country] =
        await GenericService.fetchGeneric<Region[]>("regions", "generics", {
          "filter[country]": country,
        });

    return GenericService.instance._memoizedRegions[country];
  }
}
