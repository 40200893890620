import { Step } from "@src/types/stores/register.store";
import { router } from "@src/router";
import {
  CguPrivacy,
  Identification,
  Personal,
  RegisterData,
} from "@src/types/services/auth.service";
import AuthService from "@src/services/auth.service";
import { useLoginStore } from "./login.store";
import { ErrorData } from "@lib/types/form";
import { Agency } from "@src/types/services/agency.service";
import AgencyService from "@src/services/agency.service";
import { i18n } from "@src/i18n";
import { HTTPError } from "ky";
import { ModalAlertBus } from "@lib/plugins/ModalAlertPlugin";
import { useAuthStore } from "./auth.store";

export const useRegisterStore = defineStore("register", () => {
  const authStore = useAuthStore();
  /*** State */
  const initialPersonal: Personal = {
    first_name: "",
    last_name: "",
    lang: i18n.global.locale.value,
    gender: undefined,
    birthdate: undefined,
    mobile_phone: undefined,
  };
  const personal = reactive<Personal>({ ...initialPersonal });

  const initialIdentification: Identification = {
    email: "",
    password: "",
    password_confirmation: "",
  };
  const identification = reactive<Identification>({ ...initialIdentification });
  const retrievalCode = ref<string>();

  const initialCguPrivacy: CguPrivacy = {
    has_approved_c_g_u: false,
    has_approved_privacy: false,
    has_marketing_emails: false,
  };
  const cguPrivacy = reactive<CguPrivacy>({ ...initialCguPrivacy });

  const register = computed((): RegisterData => {
    const register: RegisterData = {
      ...identification,
      ...cguPrivacy,
      first_name: personal.first_name,
      last_name: personal.last_name,
      lang: personal.lang,
      gender: personal.gender,
      birthdate: personal.birthdate,
      mobile_phone: personal.mobile_phone,
      agency: (router.currentRoute.value.query.agency as string) ?? undefined,
      retrieval_code: retrievalCode.value,
    };

    if (typeof register.birthdate !== "string")
      register.birthdate = dayjs(register.birthdate).format("YYYY-MM-DD");

    return register;
  });

  const registerErrors = ref<Record<string, string>>({});

  const step = ref<Step>("landing");

  const loading = ref(false);
  const blockEmailInput = ref(false);

  const agency = ref<Agency>();

  /*** Actions */
  async function legacyHash(hash: string) {
    loading.value = true;
    try {
      const response = await AuthService.legacyHash(hash);
      Object.assign(personal, response.data);
      Object.assign(identification, {
        email: response.data.email,
      });
      blockEmailInput.value = true;
      retrievalCode.value = hash;
      step.value = "personal";
    } catch (e) {
      loading.value = false;
      throw e;
    }
    loading.value = false;
  }

  async function lookupAgency(_agency: string) {
    loading.value = true;
    agency.value = await AgencyService.lookupAgency(_agency);
    loading.value = false;
  }

  function reset() {
    step.value = "landing";
    blockEmailInput.value = false;
    Object.assign(personal, initialPersonal);
    Object.assign(identification, initialIdentification);
    Object.assign(cguPrivacy, initialCguPrivacy);
  }

  function setRegisterErrors(errors: Record<string, string>) {
    registerErrors.value = errors;

    if (
      Object.keys(errors).find((value) => Object.keys(personal).includes(value))
    )
      step.value = "personal";
    else if (
      Object.keys(errors).find((value) =>
        Object.keys(identification).includes(value)
      )
    )
      step.value = "identification";
  }

  async function createAccount() {
    registerErrors.value = {};
    try {
      await AuthService.register(
        register.value,
        authStore.agency ? { agency: authStore.agency.slug ?? "" } : undefined
      );
      await AuthService.login({
        email: identification.email!,
        password: identification.password!,
      });
      return true;
    } catch (e) {
      if (
        e instanceof HTTPError &&
        [400, 404, 409, 422].includes(e.response.status)
      ) {
        if (e.response.status === 422) {
          const errorData = await ErrorData.fromHTTPError(e);
          if (errorData && errorData.violations) {
            setRegisterErrors(errorData.violations);
          }
        } else {
          const response = await e.response.clone().json();
          if (!response.errors?.required_action) {
            ModalAlertBus.modalAlert({
              type: "error",
              title: response.errors.message,
            });
          }
        }
        return false;
      } else {
        throw e;
      }
    }
  }

  return {
    step,
    personal,
    identification,
    cguPrivacy,
    register,
    registerErrors,
    loading,
    blockEmailInput,
    agency,
    retrievalCode,
    legacyHash,
    lookupAgency,
    setRegisterErrors,
    reset,
    createAccount,
  };
});
