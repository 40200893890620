import {
  FormKitExtendableSchemaRoot,
  FormKitNode,
  FormKitPlugin,
} from "@formkit/core";

import RequiredAsterisk from "@src/components/form/plugins/RequiredAsterisk.vue";

const isCheckboxAndRadioMultiple = (node: FormKitNode) =>
  (node.props.type === "checkbox" || node.props.type === "radio") &&
  node.props.options;

const requiredAsteriskPlugin: FormKitPlugin = (node) => {
  node.on("created", () => {
    if (node.props.definition && node.props.definition.schema) {
      const schemaFn = node.props.definition
        .schema as FormKitExtendableSchemaRoot;
      node.props.definition.schema = (sectionsSchema = {}) => {
        const isRequired = node.props.parsedRules.some(
          (rule: { name: string }) => rule.name === "required"
        );

        if (isRequired) {
          const child = {
            $cmp: markRaw(RequiredAsterisk as any),
          };

          if (isCheckboxAndRadioMultiple(node)) {
            sectionsSchema.legend = {
              children: ["$label", child],
            };
          } else {
            sectionsSchema.label = {
              children: ["$label", child],
            };
          }
        }
        return schemaFn(sectionsSchema);
      };
    }
  });
};

export default requiredAsteriskPlugin;
