<template>
    <Transition name="fade">
        <YAlert class="mx-auto" :size="toast.size" :icon="toast.icon" :variant="toast.variant">
            <h4>{{ toast.title }}</h4>
            <template v-if="toast.dismissible" #after>
                <YButton
                    outline
                    borderless
                    variant="error"
                    shape="square"
                    size="xs"
                    @click.prevent="fade"
                >
                    <fa-regular-xmark class="text-lg"/>
                </YButton>
            </template>
        </YAlert>
    </Transition>
</template>

<script setup lang="ts">
import { Toast } from '@lib/types/plugins/ToastPlugin';
import YAlert from '../YAlert.vue';
import YButton from '../YButton/YButton.vue';

interface Props {
    toast: Toast,
}

interface Emits {
    (e: "fade"): void
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();
const timeout = ref();

const fade = () => emits("fade");

onMounted(() => {
    timeout.value = setTimeout(fade, props.toast.duration);
});

onBeforeUnmount(() => {
    clearTimeout(timeout.value);
})
</script>

<style>
.y-toast {
    @apply alert py-2 border;
}
</style>