import { useAuthStore } from "@src/store/auth.store";
import { RouteMiddleware } from "@src/types/router";

export const userRequirementsMiddleware: RouteMiddleware = (
  to,
  from,
  redirectTo: string | undefined
) => {
  const authStore = useAuthStore();

  if (!authStore.user?.hasRequirements) {
    return { name: redirectTo ?? "404" };
  }
};
